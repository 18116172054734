module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alpha TMS","short_name":"alpha","start_url":"/","background_color":"hsl(28, 72%, 57%)","theme_color":"hsl(28, 72%, 57%)","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"818a4fbc77d40cc26619a72befc8327c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NNHNR4Z"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
